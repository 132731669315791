import { useMemo } from "react";
import { useQuery } from "react-query";

import { MaterialBalanceApi } from "@packages/service-api";

export const useProducts = (productIds: string[], skipAuth: boolean) => {
  const keys = useMemo(() => {
    return productIds.toString();
  }, [productIds]);
  const { data, isLoading } = useQuery(
    ["productsIds", keys],
    () => {
      return Promise.all(
        productIds.map((productId) => {
          return MaterialBalanceApi.Products.getOne(productId, skipAuth);
        })
      );
    },
    {
      enabled: Boolean(productIds.length)
    }
  );

  const productsList = useMemo(() => {
    if (data?.length) {
      return data.map((item) => item.data);
    }
    return [];
  }, [data]);

  return {
    products: productsList,
    loading: isLoading
  };
};
