import React from "react";
import ReactDOM from "react-dom";
import singleSpaReact from "single-spa-react";

import MessageBus from "@packages/message-bus";
import { errorBoundary } from "@packages/theme-mui-v5";

import MaterialBalance from "./MaterialBalance";
import config from "./config";

const lifeCycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: MaterialBalance,
  errorBoundary
});

export const mount = async (props) => {
  MessageBus.publish("app.navbar.title", { title: config.displayName });
  MessageBus.publish("app.load", config);
  return lifeCycles.mount(props);
};

export const unmount = async (props) => {
  MessageBus.publish("app.unload", {});
  return lifeCycles.unmount(props);
};

export const { bootstrap } = lifeCycles;
