import { dayjs } from "@packages/utils";
import { useMutation, useQueryClient } from "react-query";

import {
  MaterialBalanceApi,
  ProductBalanceNodeUpdate,
  ProductBalanceDocumentResponse,
  ProductBalanceListResponse
} from "@packages/service-api";
import { useSnackbar } from "@packages/theme-mui-v5";

export const useUpdateProductBalanceNodes = (productId: string, productBalanceId: string) => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const { mutateAsync, isLoading, error, data } = useMutation(
    (updates: ProductBalanceNodeUpdate[]) =>
      MaterialBalanceApi.ProductBalances.Nodes.update(productId, productBalanceId, updates),
    {
      onSuccess: (newBalance, updates) => {
        const date = dayjs.utc(newBalance.data.balanceDate).format("MMYY");

        // update product balances fetched for the month view
        queryClient.setQueryData<ProductBalanceListResponse | undefined>(
          ["product-balances-by-month", productId, "byDateRange", date],
          (currentState) => {
            if (!currentState) return undefined;

            return {
              ...currentState,
              data: currentState.data.map((state) => {
                return state.productBalanceId === newBalance.data.productBalanceId
                  ? newBalance.data
                  : state;
              })
            };
          }
        );

        // update the product balance for the daily view
        queryClient.setQueryData<ProductBalanceDocumentResponse | undefined>(
          [
            "product-balances",
            productId,
            "single",
            dayjs(newBalance.data.balanceDate).format("YYYY-MM-DD")
          ],
          () => newBalance
        );

        enqueueSnackbar(
          `Product balance node${updates.length > 1 ? "s" : ""} updated successfully.`,
          {
            variant: "success"
          }
        );
      }
    }
  );

  return {
    productBalance: data,
    error: error,
    loading: isLoading,
    updateProductBalance: mutateAsync
  };
};
