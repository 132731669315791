import { MutationOptions, useMutation, useQueryClient } from "react-query";

import { CsvTemplatesListResponse, HTTPError } from "../../../types";
import { useSelectedLocation } from "../..";
import { MaterialBalanceApi } from "../../../apis";
import { csvTemplateListQueryKey, csvTemplatesByIdQueryKey } from "./query-cache";

export const useDeleteCsvTemplate = (applicationId: string) => {
  const queryClient = useQueryClient();
  const { siteId } = useSelectedLocation(applicationId);

  const queryKey = csvTemplateListQueryKey(siteId);

  const mutationOptions: MutationOptions<void, HTTPError, string> = {
    mutationFn: (csvTemplateId: string) => MaterialBalanceApi.CsvTemplates.delete(csvTemplateId),
    onSuccess: (_response, csvTemplateId) => {
      queryClient.invalidateQueries(csvTemplatesByIdQueryKey(csvTemplateId));

      queryClient.setQueryData<CsvTemplatesListResponse | undefined>(
        queryKey,
        (currentState: CsvTemplatesListResponse | undefined) => {
          if (!currentState) return undefined;

          // Delete
          const templates = currentState.data.filter((state) => {
            return state.csvTemplateId !== csvTemplateId;
          });

          return {
            ...currentState,
            data: [...templates]
          };
        }
      );
    }
  };

  const { mutateAsync, isLoading, isSuccess, isError, error } = useMutation(mutationOptions);

  return {
    deleteCsvTemplate: mutateAsync,
    loading: isLoading,
    isSuccess,
    isError,
    error
  };
};
