import { useMemo } from "react";
import { useQueryClient } from "react-query";
import { ProductListResponse, useSelectedLocation } from "@packages/service-api";

import { applicationId } from "../../config";

export const useGetProductById = (productId: string) => {
  const { selectedLocation } = useSelectedLocation(applicationId);
  const queryClient = useQueryClient();
  const products = queryClient.getQueryState<ProductListResponse>([
    "products",
    selectedLocation?.locationId
  ])?.data?.data;

  const product = useMemo(() => {
    return products?.find((product) => product.productId === productId);
  }, [products, productId]);

  return {
    selectedProduct: product
  };
};
