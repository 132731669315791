import { MutationOptions, useMutation, useQueryClient } from "react-query";

import { MaterialBalanceApi } from "../../../apis";
import { CsvTemplateDocumentResponse, CsvTemplatesListResponse, HTTPError } from "../../../types";
import { csvTemplateListQueryKey, csvTemplatesByIdQueryKey } from "./query-cache";
import { useSelectedLocation } from "../../auth";
import { extractHttpErrorMessage } from "../../utils/http-error";

export const useUploadCsvTemplateFile = (applicationId: string, csvTemplateId: string) => {
  const queryClient = useQueryClient();
  const { siteId } = useSelectedLocation(applicationId);

  const mutationOptions: MutationOptions<CsvTemplateDocumentResponse, HTTPError, File> = {
    mutationFn: async (file: File) => {
      await MaterialBalanceApi.CsvTemplates.uploadTemplateFile(csvTemplateId, file);

      return new Promise<CsvTemplateDocumentResponse>((resolve, reject) => {
        let pollCount = 1;
        const interval = setInterval(async () => {
          try {
            const response = await MaterialBalanceApi.CsvTemplates.getCsvTemplateById(
              csvTemplateId
            );

            if (!response?.data?.fileUploaded) {
              if (pollCount > 5) {
                clearInterval(interval);
                throw new Error("It took too long to see the newly uploaded file");
              }

              pollCount += 1;
              return;
            }

            clearInterval(interval);
            return resolve(response);
          } catch (error) {
            return reject(error as HTTPError);
          }
        }, 5000);
      });
    },
    onError: (error) => extractHttpErrorMessage(error),
    onSuccess: (response) => {
      queryClient.setQueryData<CsvTemplateDocumentResponse | undefined>(
        csvTemplatesByIdQueryKey(csvTemplateId),
        () => response
      );

      queryClient.setQueryData<CsvTemplatesListResponse | undefined>(
        csvTemplateListQueryKey(siteId),
        (currentState: CsvTemplatesListResponse | undefined) => {
          if (!currentState) return undefined;

          currentState.data = currentState.data.map((state) =>
            state.csvTemplateId === response?.data?.csvTemplateId ? response.data : state
          );

          return {
            ...currentState,
            data: [...currentState.data]
          };
        }
      );
    }
  };

  const { mutateAsync, isLoading, isSuccess, isError, data, error } = useMutation(mutationOptions);

  return {
    uploadTemplateFile: mutateAsync,
    loading: isLoading,
    isSuccess,
    isError,
    calculation: data,
    error: error?.message
  };
};
