import * as React from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import {
  ApplicationIcon,
  ApplicationIconName,
  Drawer,
  RenderLabelValue,
  WithLoader
} from "@packages/theme-mui-v5";
import ProductBalanceNodeForm, {
  ProductBalanceNodeFormRef
} from "../../components/ProductBalanceNodeForm";
import { ProductBalanceNode } from "@packages/service-api";

import { useProductBalance } from "../../hooks/product-balances/useProductBalance";
import { useUpdateProductBalanceNode } from "../../hooks/product-balances";
import { formatAccountingNumber, dayjs } from "@packages/utils";
import { useProduct } from "../../hooks/products";

const EditProductBalance = () => {
  const {
    id: productId,
    productBalanceId,
    nodeId
  } = useParams<{
    id: string;
    productBalanceId: string;
    nodeId: string;
  }>();

  const {
    productBalance,
    error,
    loading: isLoading
  } = useProductBalance(productId, productBalanceId);

  const { product, loading: productLoading } = useProduct(productId);

  const { updateProductBalance, loading: saving } = useUpdateProductBalanceNode(
    productId,
    productBalanceId
  );

  const navigate = useNavigate();
  const ref = React.useRef<ProductBalanceNodeFormRef>(null);

  const selectedNode = React.useMemo(() => {
    return productBalance?.nodes.find((n) => n.nodeId === nodeId);
  }, [nodeId, productBalance]);

  React.useEffect(() => {
    if (error) {
      navigate("/product-balances");
    }
  }, [error]);

  const returnToBalance = React.useCallback(() => {
    let url = "/product-balances";

    if (productBalance) {
      const date = dayjs(productBalance.balanceDate);
      url = `/product-balances?tab=monthly&date=${date.format("YYYY-MM-DD")}&product=${productId}`;
    }

    navigate(url);
  }, [productBalance]);

  const handleSubmit = async (values: Partial<ProductBalanceNode>) => {
    const { adjustmentQty, comment, nodeId, specifiedQty } = values;
    await updateProductBalance([{ nodeId, adjustmentQty, comment, specifiedQty }]);

    returnToBalance();
  };

  const onSave = () => {
    ref.current && ref.current.onSubmit();
  };

  const loading = isLoading || productLoading || saving;

  return (
    <Drawer
      title={selectedNode ? `Node Balance | ${selectedNode.nodeName}` : "Loading..."}
      contentTitle={
        selectedNode
          ? `${selectedNode.nodeName} - ${dayjs(productBalance.balanceDate).format("YYYY-MM-DD")}`
          : ""
      }
      onClose={returnToBalance}
      actions={[
        {
          text: productBalance && !productBalance.isLocked && "Save",
          action: onSave,
          disabled: loading
        },
        {
          text: !productBalance?.isLocked ? "Cancel" : "Close",
          action: returnToBalance,
          variant: "outlined"
        }
      ]}
      loading={loading}
    >
      <WithLoader loading={loading}>
        {productBalance && (
          <>
            {productBalance.isLocked ? (
              <Box>
                <Box>
                  <Box sx={{ pb: 2, display: "flex", color: "primary.main", alignItems: "center" }}>
                    <ApplicationIcon name={"lock" as ApplicationIconName} />
                    <Box sx={{ pl: 1, position: "relative", fontWeight: 500 }}>Lock</Box>
                  </Box>
                  <RenderLabelValue
                    label="Measured Quantity"
                    value={formatAccountingNumber(selectedNode?.measuredQty)}
                    isNumber
                  />
                  {!product?.hideAdjustments && (
                    <RenderLabelValue
                      label="Adjustments"
                      value={formatAccountingNumber(selectedNode?.adjustmentQty)}
                      isNumber
                    />
                  )}
                  <RenderLabelValue
                    label="Resolved Quantity"
                    value={formatAccountingNumber(selectedNode?.resolvedQty)}
                    isNumber
                  />
                  <RenderLabelValue
                    label="Specified Quantity"
                    value={formatAccountingNumber(selectedNode?.specifiedQty)}
                    isNumber
                  />
                  <RenderLabelValue label="Comments" value={selectedNode?.comment} />
                  <RenderLabelValue label="System Message" value={productBalance?.systemMessage} />
                </Box>
              </Box>
            ) : (
              <ProductBalanceNodeForm
                node={selectedNode}
                hideAdjustments={product?.hideAdjustments}
                systemMessage={productBalance?.systemMessage}
                onSubmit={handleSubmit}
                ref={ref}
              />
            )}
          </>
        )}
      </WithLoader>
    </Drawer>
  );
};

export default EditProductBalance;
