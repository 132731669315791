import { useQuery } from "react-query";

import { MaterialBalanceApi, ProductBalanceDocumentResponse } from "@packages/service-api";

export const useProductBalance = (productId: string, productBalanceId: string) => {
  const { data, error, isLoading } = useQuery<ProductBalanceDocumentResponse, Error>(
    ["product-balances", "id", productBalanceId],
    () => MaterialBalanceApi.ProductBalances.getById(productId, productBalanceId),
    {
      enabled: Boolean(productId && productBalanceId)
    }
  );

  return {
    productBalance: data?.data,
    error: error,
    loading: isLoading
  };
};
