import { useMemo } from "react";
import { useQuery } from "react-query";

import { ProductBalanceListResponse, MaterialBalanceApi } from "@packages/service-api";

export const useProductBalanceByDateRange = (
  productId: string,
  startDate: string,
  endDate: string
) => {
  const { data, error, isError, isLoading, isFetching, refetch, status } = useQuery<
    ProductBalanceListResponse,
    Error
  >(
    ["product-balances-by-month", productId, "date-range", `${startDate}-${endDate}`],
    () => MaterialBalanceApi.ProductBalances.getByDateRange(productId, startDate, endDate),
    {
      enabled: Boolean(productId && startDate && endDate)
    }
  );

  const productBalancesMonthly = useMemo(() => {
    if (Array.isArray(data?.data)) {
      data.data.map((balance) => {
        balance.nodes.sort((n) => (n.nodeType === "Producer" ? -1 : 1));
      });

      return data.data.sort((a, b) =>
        new Date(a.balanceDate).getTime() > new Date(b.balanceDate).getTime() ? 1 : -1
      );
    }

    return [];
  }, [data?.data]);

  return {
    productBalancesMonthly,
    error: error,
    isError,
    loading: isLoading,
    isFetching: isFetching,
    status,
    refetchProductBalanceByDateRange: refetch
  };
};
