import { useMutation, useQueryClient } from "react-query";
import { MaterialBalanceApi, ProductListResponse } from "@packages/service-api";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "@packages/theme-mui-v5";
import { useSelectedLocation } from "@packages/service-api";
import { applicationId } from "../../config";

export const useDeleteProduct = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { selectedLocation } = useSelectedLocation(applicationId);

  const { mutateAsync, isLoading, isError, data, isSuccess } = useMutation(
    (productId: string) => MaterialBalanceApi.Products.delete(productId),
    {
      onSuccess: (_product, productId) => {
        queryClient.setQueryData<ProductListResponse | undefined>(
          ["products", selectedLocation.locationId],
          (currentState: ProductListResponse | undefined) => {
            if (!currentState) return undefined;

            // Delete
            const newEntities = currentState.data.filter((state) => {
              return state.productId !== productId;
            });

            return {
              ...currentState,
              data: [...newEntities]
            };
          }
        );
        enqueueSnackbar("Product deleted successfully.", {
          variant: "success"
        });
        navigate("/products");
      },
      onError: () => {
        enqueueSnackbar("Unfortunately, We are unable to save your changes. Please try again.", {
          variant: "error"
        });
        navigate("/products");
      }
    }
  );

  return {
    product: data,
    deleteProduct: mutateAsync,
    loading: isLoading,
    isError,
    isSuccess
  };
};
