import { useMemo } from "react";
import { useQueryClient, useQuery } from "react-query";
import { useSelectedLocation } from "@packages/service-api";
import { applicationId } from "../../config";
import { MaterialBalanceApi, ProductListResponse } from "@packages/service-api";

export const useProduct = (productId: string) => {
  const { selectedLocation } = useSelectedLocation(applicationId);
  const queryClient = useQueryClient();
  const productList = queryClient.getQueryState<ProductListResponse>([
    "products",
    selectedLocation?.locationId
  ])?.data;

  const { data: products, isLoading } = useQuery<ProductListResponse>(
    ["products", selectedLocation?.locationId],
    () => MaterialBalanceApi.Products.getByLocationId(selectedLocation?.locationId),
    {
      enabled: !productList?.data.length && !!selectedLocation?.locationId
    }
  );

  const product = useMemo(() => {
    return products && products.data.find((g) => g.productId === productId);
  }, [products, productId]);

  return {
    loading: isLoading,
    product
  };
};
