import { useQuery } from "react-query";

import { MaterialBalanceApi, ProductListResponse } from "@packages/service-api";
import { useSelectedLocation } from "@packages/service-api";

import { applicationId } from "../../config";

export const useProductByLocation = () => {
  const { loading: selectedLocationLoading, selectedLocation } = useSelectedLocation(applicationId);
  const { data, error, isLoading, isFetching, refetch } = useQuery<ProductListResponse, Error>(
    ["products", selectedLocation?.locationId],
    () => MaterialBalanceApi.Products.getByLocationId(selectedLocation.locationId),
    {
      enabled: Boolean(selectedLocation?.locationId)
    }
  );

  return {
    products: data ? data.data : [],
    error: error,
    loading: selectedLocationLoading || isLoading,
    isFetching: isFetching,
    refetchProducts: refetch
  };
};
