import { useQuery } from "react-query";

import { useSelectedLocation } from "@packages/service-api";
import { MaterialBalanceApi, ProductListResponse } from "@packages/service-api";
import { applicationId } from "../../config";

export const useProductsByCurrentSite = () => {
  const { selectedLocation, loading: locationLoading } = useSelectedLocation(applicationId);

  const { data, error, isLoading, isFetching, refetch } = useQuery<ProductListResponse, Error>(
    ["products", "current-site", selectedLocation?.siteId],
    () => MaterialBalanceApi.Products.getBySiteId(selectedLocation?.siteId),
    { enabled: selectedLocation !== undefined }
  );
  return {
    products: data ? data.data : [],
    error: error,
    loading: locationLoading || isLoading,
    isFetching: isFetching,
    refetchProducts: refetch
  };
};
