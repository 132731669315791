import { useMutation, useQueryClient } from "react-query";

import { MaterialBalanceApi } from "@packages/service-api";
import { useSnackbar } from "@packages/theme-mui-v5";
import { Payload } from "../../components/EditProductBalanceNodeForm";

const updateBulkProductBalanceNodes = async (
  productId: string,
  isReprocessBalance: boolean,
  updates: Payload[]
) => {
  try {
    await Promise.all(
      updates.map(({ updates, productBalanceId }) => {
        return MaterialBalanceApi.ProductBalances.Nodes.update(
          productId,
          productBalanceId,
          updates
        );
      })
    );
  } catch (error) {
    throw new Error("Failed to update product balances nodes, please try again");
  }

  if (isReprocessBalance) {
    try {
      await Promise.all(
        updates.map(({ productBalanceId }) => {
          return MaterialBalanceApi.ProductBalances.recalculate(productId, productBalanceId);
        })
      );
    } catch (error) {
      throw new Error("Failed to reprocess balances, please try again.");
    }
  }
};

export const useUpdateBulkProductBalanceNode = (productId: string, isReprocessBalance: boolean) => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const { mutateAsync, isLoading, error, data } = useMutation(
    (updates: Payload[]) => updateBulkProductBalanceNodes(productId, isReprocessBalance, updates),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["product-balances-by-month"]);
        enqueueSnackbar("Product balance nodes updated successfully.", {
          variant: "success"
        });
      },
      onError: () => {
        enqueueSnackbar("Unfortunately, We are unable to save your changes. Please try again.", {
          variant: "error"
        });
      }
    }
  );

  return {
    productBalance: data,
    error: error,
    loading: isLoading,
    updateProductBalance: mutateAsync
  };
};
