import { useMutation } from "react-query";

import { MaterialBalanceApi } from "../../../apis";

export const useGenerateCsvReport = (csvTemplateId: string) => {
  const { mutateAsync, isLoading, isSuccess, isError, data, error } = useMutation({
    mutationFn: (config: Partial<unknown>) => {
      return MaterialBalanceApi.CsvTemplates.generateCsvReport(csvTemplateId, config);
    }
  });

  return {
    generateCsvReport: mutateAsync,
    loading: isLoading,
    isSuccess,
    isError,
    csvReport: data,
    error
  };
};
