import { MutationOptions, useMutation, useQueryClient } from "react-query";

import {
  CsvTemplate,
  CsvTemplateDocumentResponse,
  CsvTemplatesListResponse,
  HTTPError
} from "../../../types";
import { useSelectedLocation } from "../..";
import { MaterialBalanceApi } from "../../../apis";
import { extractHttpErrorMessage } from "../../utils/http-error";
import { csvTemplateListQueryKey, csvTemplatesByIdQueryKey } from "./query-cache";

export const useCreateCsvTemplate = (applicationId: string) => {
  const queryClient = useQueryClient();

  const { siteId } = useSelectedLocation(applicationId);

  const mutationOptions: MutationOptions<
    CsvTemplateDocumentResponse | undefined,
    HTTPError,
    CsvTemplate
  > = {
    mutationFn: (csvTemplate: CsvTemplate) =>
      MaterialBalanceApi.CsvTemplates.create({
        ...csvTemplate,
        siteId
      }),
    onError: (error) => extractHttpErrorMessage(error),
    onSuccess: (response) => {
      if (!response) return;

      queryClient.setQueryData<CsvTemplateDocumentResponse | undefined>(
        csvTemplatesByIdQueryKey(response.data.csvTemplateId),
        response
      );

      queryClient.setQueryData<CsvTemplatesListResponse | undefined>(
        csvTemplateListQueryKey(siteId),
        (currentState: CsvTemplatesListResponse | undefined) => {
          if (!currentState) return undefined;

          return {
            ...currentState,
            data: [...currentState.data, response.data]
          };
        }
      );
    }
  };

  const { mutateAsync, isLoading, isSuccess, isError, data, error } = useMutation(mutationOptions);

  return {
    createCsvTemplate: mutateAsync,
    csvTemplate: data?.data,
    error: error?.message,
    isError,
    isSuccess,
    loading: isLoading
  };
};
