import { useQuery } from "react-query";

import { MaterialBalanceApi, ProductBalanceDocumentResponse } from "@packages/service-api";

export const useProductBalanceByDate = (productId: string, date: string) => {
  const { data, error, isError, isLoading, isFetching, refetch, status } = useQuery<
    ProductBalanceDocumentResponse,
    Error
  >(
    ["product-balances", productId, "single", date],
    () => MaterialBalanceApi.ProductBalances.getByDate(productId, date),
    {
      enabled: Boolean(productId && date)
    }
  );

  return {
    productBalance: data?.data,
    error: error,
    isError,
    loading: isLoading,
    isFetching: isFetching,
    status,
    refetchProductBalanceByDate: refetch
  };
};
