import { UseQueryOptions, useQuery } from "react-query";
import { CsvTemplate, CsvTemplatesListResponse, HTTPError, QueryOptions } from "../../../types";
import { useSelectedLocation } from "../..";
import { MaterialBalanceApi } from "../../../apis";

import { csvTemplateListQueryKey } from "./query-cache";
import { extractHttpErrorMessage } from "../../utils/http-error";

export const useGetCsvTemplates = (
  applicationId: string,
  query: Partial<CsvTemplate> = {},
  queryOptions: QueryOptions = {}
) => {
  const { siteId } = useSelectedLocation(applicationId);

  const queryKey = csvTemplateListQueryKey(siteId);

  const fetchQueryOptions: UseQueryOptions<CsvTemplatesListResponse, HTTPError> = {
    enabled: !!siteId,
    onError: extractHttpErrorMessage,
    queryFn: () =>
      MaterialBalanceApi.CsvTemplates.getCsvTemplates({ ...query, siteId }, queryOptions),
    queryKey
  };

  const { data, error, isError, isLoading, isFetching, refetch } = useQuery(fetchQueryOptions);

  return {
    templates: data?.data,
    error: error?.message,
    isError,
    loading: isLoading,
    isFetching: isFetching,
    refetch
  };
};
