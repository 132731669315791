import * as React from "react";
import { useForm } from "react-hook-form";
import { FormGroup, Paper } from "@mui/material";
import { TextField, RenderLabelValue } from "@packages/theme-mui-v5";
import { ProductBalanceNode } from "@packages/service-api";
import { formatAccountingNumber } from "@packages/utils";

interface ProductBalanceNodeFormProp {
  node: ProductBalanceNode | Partial<ProductBalanceNode>;
  systemMessage?: string;
  hideAdjustments?: boolean;
  hideAverage?: boolean;
  onSubmit?: (formFields: unknown) => void;
  onClose?: () => void;
}

export type ProductBalanceNodeFormRef = {
  onSubmit: () => void;
};

const ProductBalanceNodeForm = React.forwardRef<
  ProductBalanceNodeFormRef,
  ProductBalanceNodeFormProp
>((props, ref) => {
  const { hideAdjustments, node, onSubmit, systemMessage } = props;
  const { handleSubmit, control, reset } = useForm<Partial<ProductBalanceNode>>({
    defaultValues: {
      ...node,
      adjustmentQty: node.adjustmentQty,
      specifiedQty: node.specifiedQty,
      description: node.description
    }
  });

  React.useImperativeHandle(ref, () => ({
    onSubmit: handleSubmit(onSubmit)
  }));

  React.useEffect(() => {
    reset(node);
  }, [node]);

  const getSystemMessage = () => {
    return (
      <p>
        {systemMessage?.split("\n").map((item) => (
          <p key={item}>{item}</p>
        ))}
      </p>
    );
  };
  return (
    <>
      <FormGroup onSubmit={handleSubmit(onSubmit)}>
        <RenderLabelValue
          label="Measured Quantity"
          value={formatAccountingNumber(node.measuredQty)}
          isNumber
        />
        {!hideAdjustments && (
          <TextField name="adjustmentQty" label="Adjustments" control={control} />
        )}
        <RenderLabelValue
          label="Resolved Quantity"
          value={formatAccountingNumber(node.resolvedQty)}
          isNumber
        />
        <TextField name="specifiedQty" label="Specified Quantity" control={control} />
        <TextField name="comment" label="Comments" control={control} />
      </FormGroup>
      <Paper sx={{ mt: 2, p: 2 }}>
        <RenderLabelValue label="System Message" value={getSystemMessage()} />
      </Paper>
    </>
  );
});

ProductBalanceNodeForm.displayName = "ProductBalanceNodeForm";

export default ProductBalanceNodeForm;
