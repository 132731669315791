import { useMemo } from "react";
import { useProductsByCurrentSite } from "./index";
import { useUserProfile } from "@packages/service-api";

export const useProductsByPemissions = (permissionsList: string[]) => {
  const {
    products: productsList,
    loading,
    isFetching,
    error,
    refetchProducts
  } = useProductsByCurrentSite();

  const profile = useUserProfile();

  const products = useMemo(() => {
    if (!productsList.length || !profile?.permissions?.length) return [];
    const myPermissions = profile.permissions.filter((permission) =>
      permissionsList.includes(permission.permissionTypeId)
    );

    let locations = [];
    myPermissions.forEach((myPermission) => {
      locations.push(...myPermission.locationIds);
    });

    locations = [...new Set([...locations])];

    return productsList.filter((product) => locations.includes(product.areaId));
  }, [profile?.permissions, productsList]);

  return {
    products,
    error,
    loading,
    isFetching: isFetching,
    refetchProducts
  };
};
