import { QueryKey } from "react-query";

type QueryKeyFunction = (...args: unknown[]) => QueryKey;

export const csvTemplateListQueryKey: QueryKeyFunction = (siteId: string) => [
  "csvTemplate",
  "list",
  siteId
];

export const csvTemplatesByIdQueryKey: QueryKeyFunction = (csvTemplatesId: string) => [
  "csvTemplate",
  "single",
  csvTemplatesId
];
