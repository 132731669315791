import { MutationOptions, useMutation } from "react-query";

import { MaterialBalanceApi } from "../../../apis";
import { extractHttpErrorMessage } from "../../utils/http-error";
import { HTTPError } from "../../../types";

export const useDownloadCsvTemplateFile = () => {
  const mutationOptions: MutationOptions<void, HTTPError, string> = {
    onError: extractHttpErrorMessage,
    mutationFn: (csvTemplateId: string) =>
      MaterialBalanceApi.CsvTemplates.downloadTemplateFile(csvTemplateId)
  };

  const { mutateAsync, isLoading, isSuccess, isError, error } = useMutation(mutationOptions);

  return {
    downloadFile: mutateAsync,
    loading: isLoading,
    isSuccess,
    isError,
    error: error?.message
  };
};
