import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "@packages/theme-mui-v5";
import { MaterialBalanceApi, Product, ProductListResponse } from "@packages/service-api";
import { useSelectedLocation } from "@packages/service-api";
import { applicationId } from "../../config";

interface Variables {
  previousProduct: Product;
  product: Partial<Product>;
}

export const useUpdateProduct = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const { selectedLocation } = useSelectedLocation(applicationId);
  const navigate = useNavigate();
  const { mutateAsync, isLoading, error, data } = useMutation(
    ({ previousProduct, product }: Variables) =>
      MaterialBalanceApi.Products.update(previousProduct, product),
    {
      onSuccess: (product) => {
        queryClient.setQueryData<ProductListResponse | undefined>(
          ["products", selectedLocation?.locationId],
          (currentState: ProductListResponse | undefined) => {
            if (!currentState) return undefined;

            // Update
            currentState.data = currentState.data.map((state) =>
              state.productId === product.data.productId ? product.data : state
            );

            return {
              ...currentState,
              data: [...currentState.data]
            };
          }
        );
        enqueueSnackbar("Product balance updated successfully.", {
          variant: "success"
        });
        navigate("/products");
      },
      onError: () => {
        enqueueSnackbar("Unfortunately, We are unable to save your changes. Please try again.", {
          variant: "error"
        });
        navigate("/products");
      }
    }
  );

  return {
    product: data,
    error: error,
    loading: isLoading,
    updateProduct: mutateAsync
  };
};
