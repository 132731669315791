import { useMutation, useQueryClient } from "react-query";
import { useSnackbar } from "@packages/theme-mui-v5";
import { MaterialBalanceApi } from "@packages/service-api";

type Variables = {
  productBalanceId: string;
};

export const useRecalculateProductBalance = (productId: string) => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const { mutateAsync, isLoading, isError, isSuccess } = useMutation(
    ({ productBalanceId }: Variables) =>
      MaterialBalanceApi.ProductBalances.recalculate(productId, productBalanceId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["product-balances", productId]);

        enqueueSnackbar("Product balance recalculating completed successfully.", {
          variant: "success"
        });
      },
      onError: () => {
        enqueueSnackbar(
          "Unfortunately, We are unable to complete the recalculating. Please try again.",
          {
            variant: "error"
          }
        );
      }
    }
  );

  return {
    recalculateBalance: mutateAsync,
    loading: isLoading,
    isError,
    isSuccess
  };
};
