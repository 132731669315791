import { useMutation, useQueryClient } from "react-query";
import { useSnackbar } from "@packages/theme-mui-v5";
import { MaterialBalanceApi, HTTPError } from "@packages/service-api";
import { useQuery } from "@packages/service-api";

const useUnLockProductBalance = (product: string, month: number, year: number) => {
  const { tab } = useQuery<{
    tab: string;
  }>();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const { mutateAsync, isLoading, isError, isSuccess, error } = useMutation(
    () => MaterialBalanceApi.ProductBalances.unLock(product, month, year),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          tab === "daily" ? "product-balances" : "product-balances-by-month"
        ]);
        enqueueSnackbar("Product balance unlocked successfully.", {
          variant: "success"
        });
        window.history.back();
      },
      onError: async (err: HTTPError) => {
        let message = "Unfortunately, We are unable to save your changes. Please try again.";

        if (err.response?.status) {
          try {
            const data = await err.response.json();
            message = data?.error;

            if (message.startsWith("Insufficient permissions")) {
              message = "You do not have permissions to perform this action";
            }

            enqueueSnackbar(message, {
              variant: "error"
            });
          } catch {
            message = "Undetermined error";
            enqueueSnackbar(message, {
              variant: "error"
            });
          }
        }
        err.message = message;
        window.history.back();
      }
    }
  );

  return {
    unLockProductBalance: mutateAsync,
    loading: isLoading,
    isError,
    isSuccess,
    error
  };
};

export default useUnLockProductBalance;
