import { UseQueryOptions, useQuery } from "react-query";

import { MaterialBalanceApi } from "../../../apis";
import { csvTemplatesByIdQueryKey } from "./query-cache";
import { extractHttpErrorMessage } from "../../utils/http-error";
import { CsvTemplateDocumentResponse, HTTPError } from "../../../types";

export const useGetCsvTemplateById = (csvTemplateId: string) => {
  const queryKey = csvTemplatesByIdQueryKey(csvTemplateId);

  const queryOptions: UseQueryOptions<CsvTemplateDocumentResponse, HTTPError> = {
    enabled: !!csvTemplateId,
    onError: extractHttpErrorMessage,
    queryFn: () => MaterialBalanceApi.CsvTemplates.getCsvTemplateById(csvTemplateId),
    queryKey
  };

  const { data, error, isLoading, isFetching, refetch } = useQuery(queryOptions);

  return {
    csvTemplate: data?.data,
    error: error?.message,
    loading: isLoading || isFetching,
    refetch
  };
};
