import React from "react";
import { Box } from "@mui/material";
import RenderLabelValue from "./RenderLabelValue";

interface Props {
  label: string;
  value: React.ReactElement | string | React.ReactNode | JSX.Element;
  editComponent: React.ReactElement;
  mode: string;
}

const ViewEditFormValue = (props: Props) => {
  const { label, value, editComponent, mode } = props;
  const isView = mode == "view";

  if (isView) {
    return (
      <Box>
        <RenderLabelValue label={label} value={value} />
      </Box>
    );
  }

  return editComponent;
};

export default ViewEditFormValue;
