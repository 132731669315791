import { MutationOptions, useMutation, useQueryClient } from "react-query";

import {
  CsvTemplate,
  CsvTemplateDocumentResponse,
  CsvTemplatesListResponse,
  HTTPError
} from "../../../types";
import { useSelectedLocation } from "../..";
import { MaterialBalanceApi } from "../../../apis";

import { useGetCsvTemplateById } from "./useGetCsvTemplateById";
import { csvTemplateListQueryKey, csvTemplatesByIdQueryKey } from "./query-cache";
import { extractHttpErrorMessage } from "../../utils/http-error";

type Variables = {
  updates: Partial<CsvTemplate>;
};

export const useUpdateCsvTemplate = (applicationId: string, csvTemplateId: string) => {
  const queryClient = useQueryClient();
  const { siteId } = useSelectedLocation(applicationId);

  const { csvTemplate } = useGetCsvTemplateById(csvTemplateId);

  const mutationOptions: MutationOptions<
    CsvTemplateDocumentResponse | undefined,
    HTTPError,
    Variables
  > = {
    mutationFn: (variables) =>
      MaterialBalanceApi.CsvTemplates.update(csvTemplateId, csvTemplate, variables.updates),
    onError: (error) => extractHttpErrorMessage(error),
    onSuccess: (response) => {
      queryClient.setQueryData<CsvTemplateDocumentResponse | undefined>(
        csvTemplatesByIdQueryKey(csvTemplateId),
        response
      );

      queryClient.setQueryData<CsvTemplatesListResponse | undefined>(
        csvTemplateListQueryKey(siteId),
        (currentState: CsvTemplatesListResponse | undefined) => {
          if (!currentState) return undefined;

          currentState.data = currentState.data.map((state) =>
            state.csvTemplateId === response.data.csvTemplateId ? response.data : state
          );

          return {
            ...currentState,
            data: [...currentState.data]
          };
        }
      );
    }
  };

  const { mutateAsync, isLoading, isSuccess, isError, data, error } = useMutation(mutationOptions);

  return {
    updateCsvTemplate: mutateAsync,
    loading: isLoading,
    isSuccess,
    isError,
    calculation: data,
    error: error?.message
  };
};
